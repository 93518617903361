import {
  DataLoadingFallback,
  PageMeta,
  Text,
  Box,
  Stack,
  Inline,
  Icon,
  Alert,
  CBButton,
} from "@cashbook/web-components"
import { useBusiness } from "@cashbook/data-store/businesses"
import { useParams, useNavigate } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../ErrorBoundary"
import { formatDate, isAfterDate, isBeforeDate } from "@cashbook/util-dates"
import {
  showPaymentsStore,
  SubscriptionDetails,
} from "@cashbook/data-store/storage"
import { useSyncExternalStore } from "react"
import classNames from "classnames"
import { NotInWeb } from "../support"

enum SubscriptionReminderStage {
  BILLING_DATE_REMINDER_STAGE1 = "BILLING_DATE_REMINDER_STAGE1",
  BILLING_DATE_REMINDER_STAGE2 = "BILLING_DATE_REMINDER_STAGE2",
  GRACE_PERIOD = "GRACE_PERIOD",
  INACTIVE = "INACTIVE",
  TRIAL_DUE_DATE_REMINDER_STATE = "TRIAL_DUE_DATE_REMINDER_STATE",
  TRIAL_EXPIRED = "TRIAL_EXPIRED",
}

function getSubscriptionStage(
  subscription: SubscriptionDetails
): SubscriptionReminderStage | undefined {
  const now = new Date()
  const billingDate = new Date(subscription.next_billing_date)
  const dueDate = subscription.payment_due_date
    ? new Date(subscription.payment_due_date)
    : undefined

  const isTrialUser = subscription?.payment_method === null

  if (isTrialUser) {
    if (subscription.status === "INACTIVE") {
      return SubscriptionReminderStage.TRIAL_EXPIRED
    }
    if (dueDate) {
      const daysToDueDate = Math.ceil(
        (dueDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
      )
      if (daysToDueDate <= 3) {
        return SubscriptionReminderStage.TRIAL_DUE_DATE_REMINDER_STATE
      }
    }
    return
  }

  if (subscription.status === "INACTIVE") {
    return SubscriptionReminderStage.INACTIVE
  }

  if (dueDate && isBeforeDate(now, dueDate) && isAfterDate(now, billingDate)) {
    return SubscriptionReminderStage.GRACE_PERIOD
  }

  const daysToBilling = Math.ceil(
    (billingDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
  )

  if (subscription)
    if (daysToBilling <= 3) {
      return SubscriptionReminderStage.BILLING_DATE_REMINDER_STAGE2
    }

  if (daysToBilling <= 15) {
    return SubscriptionReminderStage.BILLING_DATE_REMINDER_STAGE1
  }

  return undefined
}

export default function BusinessSubscriptionPage() {
  const { businessId } = useParams()
  if (!businessId) return null

  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={
          <DataLoadingFallback label="Loading subscription details..." />
        }
        traceId="loading_subscription_details"
      >
        <SubscriptionLayout businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function SubscriptionLayout({ businessId }: { businessId: string }) {
  const { authTeamMemberDetails } = useBusiness(businessId)
  const navigate = useNavigate()
  const role = authTeamMemberDetails?.role?.id
  const isOwner = role === "owner"

  const showPayments = useSyncExternalStore(
    showPaymentsStore.subscribe,
    showPaymentsStore.getShowPaymentsList
  )

  const subscription = showPayments[businessId]?.subscription
  const isActive = subscription?.status === "ACTIVE"

  const isTrialUser = subscription?.payment_method === null

  const formatDateString = (date: string) =>
    formatDate(new Date(date), "MMMM d, yyyy")

  const handleViewInvoices = () => {
    navigate(
      `/businesses/${businessId}/business-settings/subscription/invoices`
    )
  }

  const renderSubscriptionStatus = () => {
    if (!subscription || !isOwner) return null

    const stage = getSubscriptionStage(subscription)

    switch (stage) {
      case SubscriptionReminderStage.TRIAL_DUE_DATE_REMINDER_STATE:
        return (
          <Alert status="warning">
            <Stack gap="1">
              <Text fontWeight="medium">Trial Expiring Soon</Text>
              <Text>
                Your CashBook Payments free trial is valid until{" "}
                {formatDateString(subscription.payment_due_date)}. Please
                purchase subscription before this date to ensure uninterrupted
                use.
              </Text>
            </Stack>
          </Alert>
        )
      case SubscriptionReminderStage.TRIAL_EXPIRED:
        return (
          <Alert status="error">
            <Stack gap="1">
              <Text fontWeight="medium">Trial Expired</Text>
              <Text>
                Your CashBook Payments free trial has expired. Please purchase
                subscription to continue using CashBook Payments.
              </Text>
            </Stack>
          </Alert>
        )
      case SubscriptionReminderStage.BILLING_DATE_REMINDER_STAGE1:
      case SubscriptionReminderStage.BILLING_DATE_REMINDER_STAGE2:
        return (
          <Alert status="warning">
            <Stack gap="1">
              <Text fontWeight="medium">Renewal Reminder</Text>
              <Text>
                Your CashBook Payments subscription is valid until{" "}
                {formatDateString(subscription.next_billing_date)}. Please renew
                your subscription before this date to ensure uninterrupted use.
              </Text>
            </Stack>
          </Alert>
        )
      case SubscriptionReminderStage.GRACE_PERIOD:
        return (
          <Alert status="error">
            <Stack gap="1">
              <Text fontWeight="medium">Grace Period Notice</Text>
              <Text>
                Your subscription has expired and CashBook Payments will stop
                working after{" "}
                {formatDateString(subscription.payment_due_date || "")}. Please
                renew your subscription before this date to ensure uninterrupted
                use.
              </Text>
            </Stack>
          </Alert>
        )
      case SubscriptionReminderStage.INACTIVE:
        return (
          <Alert status="error">
            <Stack gap="1">
              <Text fontWeight="medium">Subscription Ended</Text>
              <Text>
                Subscription ended on{" "}
                {formatDateString(subscription.next_billing_date)} due to
                failure to make payment for next year
              </Text>
            </Stack>
          </Alert>
        )
      default:
        return null
    }
  }

  const renderSubscriptionCTA = () => {
    if (!subscription) return null
    const stage = getSubscriptionStage(subscription)
    if (
      stage === SubscriptionReminderStage.INACTIVE ||
      stage === SubscriptionReminderStage.GRACE_PERIOD ||
      stage === SubscriptionReminderStage.BILLING_DATE_REMINDER_STAGE1 ||
      stage === SubscriptionReminderStage.BILLING_DATE_REMINDER_STAGE2
    ) {
      return (
        <NotInWeb heading="Use mobile app to renew subscription">
          {({ onDisplay }) => (
            <CBButton
              size="lg"
              level="primary"
              status="success"
              onClick={onDisplay}
            >
              Renew Subscription
            </CBButton>
          )}
        </NotInWeb>
      )
    }

    if (
      stage === SubscriptionReminderStage.TRIAL_DUE_DATE_REMINDER_STATE ||
      stage === SubscriptionReminderStage.TRIAL_EXPIRED
    ) {
      return (
        <NotInWeb heading="Use mobile app to renew subscription">
          {({ onDisplay }) => (
            <CBButton
              size="lg"
              level="primary"
              status="success"
              onClick={onDisplay}
            >
              Purchase Subscription
            </CBButton>
          )}
        </NotInWeb>
      )
    }

    if (isActive) {
      return (
        <NotInWeb heading="Use mobile app to add more wallets">
          {({ onDisplay }) => (
            <CBButton size="lg" level="primary" onClick={onDisplay}>
              Add More Wallets
            </CBButton>
          )}
        </NotInWeb>
      )
    }

    return null
  }

  return (
    <>
      <PageMeta title="Subscription Details" />
      <Box maxWidth="2xl" padding="6">
        <Stack gap="6">
          <Box padding={"4"}>
            <Stack gap="6">
              <Box className="border-b pb-4">
                <Inline gap="2" alignItems="center">
                  <Icon
                    name={isActive ? "check-circle" : "x-circle"}
                    size="5"
                    className={classNames(
                      isActive ? "text-green-500" : "text-red-500"
                    )}
                  />
                  <Text fontSize="lg" fontWeight="medium">
                    {isActive
                      ? isTrialUser
                        ? "Trial Active"
                        : "Active Subscription"
                      : "Inactive Subscription"}
                  </Text>
                </Inline>
              </Box>

              <Stack gap="6" marginLeft="6">
                <Box>
                  <Text color="gray500" fontSize="sm">
                    {isActive ? "Current Plan" : "Last Active Plan"}
                  </Text>
                  <Text fontSize="lg" fontWeight="medium">
                    {isTrialUser
                      ? "CashBook Payments Free Trial"
                      : subscription?.plan?.name || "No active plan"}
                  </Text>
                </Box>

                <Inline gap="8">
                  {!isTrialUser && (
                    <Box>
                      <Text color="gray500" fontSize="sm">
                        Amount
                      </Text>
                      <Text fontSize="lg">
                        ₹{subscription?.next_billing_amount}
                      </Text>
                    </Box>
                  )}
                  {!isTrialUser && (
                    <Box>
                      <Text color="gray500" fontSize="sm">
                        Billing Cycle
                      </Text>
                      <Text fontSize="lg">{subscription?.plan?.frequency}</Text>
                    </Box>
                  )}
                  {isTrialUser ? (
                    <Box>
                      <Text color="gray500" fontSize="sm">
                        Trial Ends On
                      </Text>
                      <Text fontSize="lg">
                        {subscription &&
                          formatDateString(subscription.payment_due_date)}
                      </Text>
                    </Box>
                  ) : (
                    <Box>
                      <Text color="gray500" fontSize="sm">
                        Next Billing
                      </Text>
                      <Text fontSize="lg">
                        {subscription &&
                          formatDateString(subscription.next_billing_date)}
                      </Text>
                    </Box>
                  )}
                  <Box>
                    <Text color="gray500" fontSize="sm">
                      {isTrialUser ? "Wallets" : "Purchased Wallets"}
                    </Text>
                    <Text fontSize="lg">
                      {subscription?.wallet_quantity || 0}
                    </Text>
                  </Box>
                </Inline>

                {renderSubscriptionStatus()}
                {isOwner && (
                  <Box className="border-t pt-6">
                    <Inline gap="4" justifyContent="start">
                      {isOwner && renderSubscriptionCTA()}
                      <CBButton
                        level="secondary"
                        size="lg"
                        onClick={handleViewInvoices}
                      >
                        View Invoice History
                      </CBButton>
                    </Inline>
                  </Box>
                )}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  )
}
