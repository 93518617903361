import React from "react"
import {
  PageMeta,
  Text,
  Box,
  Stack,
  Inline,
  Icon,
  CBButton,
  DataLoadingFallback,
  ArrowLeftIcon,
} from "@cashbook/web-components"
import { formatDate } from "@cashbook/util-dates"
import { useSubscriptionInvoices } from "@cashbook/data-store/payments"
import { useMount } from "@cashbook/util-general"
import { SubscriptionInvoice } from "@cashbook/data-store/storage"
import { useParams, useNavigate } from "react-router-dom"

export default function BusinessSubscriptionInvoicesPage() {
  const { businessId } = useParams()
  const navigate = useNavigate()

  const { isLoading, error, invoices, fetchInvoices } = useSubscriptionInvoices(
    businessId || ""
  )

  useMount(() => {
    fetchInvoices()
  })

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleDownloadInvoice = async (invoice: SubscriptionInvoice) => {
    try {
      // Open invoice in new tab
      window.open(invoice.receipt, "_blank")
    } catch (error) {
      console.error("Error opening invoice:", error)
      // You may want to show a toast/notification here about the error
    }
  }

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "paid":
        return "text-green-500"
      case "pending":
        return "text-yellow-500"
      case "failed":
        return "text-red-500"
      default:
        return "text-gray-500"
    }
  }

  const getStatusIcon = (status: string) => {
    switch (status.toLowerCase()) {
      case "paid":
        return "check-circle"
      case "pending":
        return "clock"
      case "failed":
        return "x-circle"
      default:
        return "help-circle"
    }
  }

  if (isLoading) {
    return <DataLoadingFallback label="Loading invoices..." />
  }

  if (error) {
    return (
      <Box padding="6">
        <Stack gap="4" alignItems="center">
          <Icon name="x-circle" size="12" className="text-red-500" />
          <Text color="textError">Failed to load invoices</Text>
          <CBButton level="secondary" size="sm" onClick={fetchInvoices}>
            Retry
          </CBButton>
        </Stack>
      </Box>
    )
  }

  return (
    <>
      <PageMeta title="Invoice History" />
      <Box maxWidth="2xl" padding="6">
        <Stack gap="6">
          <Box padding="4">
            <Stack gap="6">
              <Box className="border-b pb-4">
                <Inline gap="4" alignItems="center">
                  <CBButton
                    level="secondary"
                    size="sm"
                    onClick={handleGoBack}
                    inline
                  >
                    <ArrowLeftIcon />
                  </CBButton>
                  <Text fontSize="lg" fontWeight="medium">
                    Invoice History
                  </Text>
                </Inline>
              </Box>

              <Stack gap="4">
                {invoices.map((invoice) => (
                  <Box
                    key={invoice.id}
                    padding="4"
                    backgroundColor="white"
                    rounded="md"
                    borderWidth="1"
                    borderColor="gray200"
                    className="hover:border-gray-300 transition-colors"
                  >
                    <Stack gap="4">
                      <Inline justifyContent="between" alignItems="center">
                        <Stack gap="1">
                          <Text color="gray500" fontSize="sm">
                            {formatDate(
                              new Date(invoice.created_at),
                              "MMMM d, yyyy"
                            )}
                          </Text>
                        </Stack>
                        <Stack gap="2" alignItems="end">
                          <Text fontWeight="medium" fontSize="lg">
                            ₹
                            {invoice.total_amount.toLocaleString("en-IN", {
                              maximumFractionDigits: 2,
                            })}
                          </Text>
                          <Inline gap="2" alignItems="center">
                            <Icon
                              name={getStatusIcon(invoice.status)}
                              size="4"
                              className={getStatusColor(invoice.status)}
                            />
                            <Text
                              className={`capitalize ${getStatusColor(
                                invoice.status
                              )}`}
                            >
                              {invoice.status.toLowerCase()}
                            </Text>
                          </Inline>
                        </Stack>
                      </Inline>

                      <Box className="border-t pt-4">
                        <Stack gap="2">
                          <Inline justifyContent="between">
                            <Text color="gray500">Amount</Text>
                            <Text>
                              ₹
                              {invoice.amount.toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                              })}
                            </Text>
                          </Inline>
                          <Inline justifyContent="between">
                            <Text color="gray500">GST</Text>
                            <Text>
                              ₹
                              {invoice.gst.toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                              })}
                            </Text>
                          </Inline>
                          {invoice.discount > 0 && (
                            <Inline justifyContent="between">
                              <Text color="gray500">Discount</Text>
                              <Text className="text-green-500">
                                -₹
                                {invoice.discount.toLocaleString("en-IN", {
                                  maximumFractionDigits: 2,
                                })}
                              </Text>
                            </Inline>
                          )}
                          <Inline justifyContent="between">
                            <Text color="gray500">Wallets</Text>
                            <Text>{invoice.details.wallet_quantity}</Text>
                          </Inline>
                        </Stack>
                      </Box>

                      <Box className="border-t pt-4">
                        <Inline justifyContent="end">
                          <CBButton
                            level="secondary"
                            size="sm"
                            onClick={() => handleDownloadInvoice(invoice)}
                          >
                            Download Invoice
                          </CBButton>
                        </Inline>
                      </Box>
                    </Stack>
                  </Box>
                ))}

                {invoices.length === 0 && (
                  <Box className="text-center py-8">
                    <Stack gap="4" alignItems="center">
                      <Icon
                        name="document"
                        size="12"
                        className="text-gray-400"
                      />
                      <Text color="gray500">No invoices found</Text>
                    </Stack>
                  </Box>
                )}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  )
}
